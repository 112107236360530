<template>
  <div
    class="d-flex flex-column animate__animated animate__fadeIn"
    style="width: 100%"
  >
    <h2 class="mb-6">
      Logga in
    </h2>
    <v-btn
      v-if="!isAnyMobileDevice()"
      id="login-bankid-btn"
      type="submit"
      class="mb-6"
      light
      rounded
      depressed
      x-large
      @click="showBottomSheet = true"
    >
      <img
        :src="bankidBlack"
        alt="BankID logo"
        style="width: 40px; margin-right: 4px"
      >
      Mobilt BankID
    </v-btn>
    <v-btn
      v-else
      id="open-bankid-btn"
      type="submit"
      class="mb-6"
      light
      rounded
      depressed
      x-large
      @click="onStartBankIDOnSameDevice()"
    >
      <img
        :src="bankidBlack"
        alt="BankID logo"
        style="width: 40px; margin-right: 4px"
      >
      Öppna BankID
    </v-btn>
    <v-btn
      v-if="isAnyMobileDevice()"
      color="secondary"
      class="secondary--text mb-6"
      rounded
      outlined
      x-large
      depressed
      @click="onStartBankIDOnAnotherDevice()"
    >
      BankID på annan enhet
    </v-btn>
    <a
      class="mb-16"
      href="https://gigport.se/mobilt-bankid-dator/"
      target="_blank"
      rel="noopener"
    >
      <v-icon color="#FFB74D">mdi-open-in-new</v-icon>
      Så loggar du in med BankID</a>
    <h2 class="mb-6">
      Har du inte GigPort?
    </h2>
    <v-btn
      color="secondary"
      class="secondary--text mb-6"
      rounded
      outlined
      x-large
      depressed
      :to="{ name: 'register-user' }"
    >
      Skapa konto
    </v-btn>
    <QRCodeDialog
      v-if="showBottomSheet"
      :show="showBottomSheet"
      qrcode-type="login"
      @cancel-login="onCancelLogin()"
    />
  </div>
</template>

<script>
import bankidBlack from '@/assets/img/bankid_black.svg'
import QRCodeDialog from '@/components/accounts/QRCodeDialog.vue'
export default {
  name: 'LoginView',
  components: {
    QRCodeDialog
  },
  data: () => ({
    bankidBlack,
    showBottomSheet: false
  }),
  computed: {},
  watch: {},
  mounted () {},
  beforeDestroy () {},
  methods: {
    isMobileDevice () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      return /android|ipad|iphone|ipod/i.test(userAgent)
    },
    isSafariOniOS () {
      return /iP(ad|od|hone).*Safari/i.test(navigator.userAgent) && !(/Chrome/i.test(navigator.userAgent) || /CriOS/i.test(navigator.userAgent))
    },
    isChromeOnAndroid () {
      return /Android/i.test(navigator.userAgent) && /Chrome/i.test(navigator.userAgent)
    },
    isAnyMobileDevice () {
      return this.isMobileDevice() || this.isSafariOniOS() || this.isChromeOnAndroid()
    },
    onCancelLogin () {
      this.showBottomSheet = false
    },
    onStartBankIDOnSameDevice () {
      // Open sameDeviceUrl in new tab
      window.open(this.sameDeviceUrl, '_blank')
    },
    onStartBankIDOnAnotherDevice () {
      this.showBottomSheet = true
    }
  }
}
</script>
