<template>
  <div
    class="d-flex flex-column animate__animated animate__fadeIn"
    style="width: 100%; max-width: 420px"
  >
    <div class="d-flex justify-center mt-4 mb-2">
      <img
        height="64"
        width="64"
        alt="BankID logo"
        src="https://static.kivra.com/assets/logo/bankid-dark.png"
      >
    </div>
    <div class="text-center text-h5 mt-8 mb-12">
      Legitimera med BankID
    </div>
    <div class="d-flex justify-center align-center mb-12">
      <div
        class="d-flex justify-center align-center"
        style="width: 240px; height: 240px"
      >
        <v-progress-circular
          v-if="!qrcodeUrl"
          indeterminate
          color="secondary"
        />
        <div
          v-if="qrcodeUrl"
          class="box"
        >
          <QRCode
            :text="qrcodeUrl"
            :size="200"
          />
        </div>
      </div>
    </div>
    <ol class="mb-12 text-body-2">
      <li>Öppna BankID-appen och klicka på QR-ikonen.</li>
      <li>Rikta kameran mot QR-koden som visas här på skärmen.</li>
    </ol>
    <v-btn
      color="#265973"
      class="secondary--text d-flex mb-6"
      rounded
      x-large
      text
      @click="onCancelLogin"
    >
      Avbryt
    </v-btn>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  AUTH_REFRESH_QRCODE,
  AUTH_REFRESH_REGISTER_QRCODE,
  GET_USER,
  SET_SHOULD_CONTINUE_REFRESH
} from '@/store/actions.type'

export default {
  name: 'BankidAuth',
  props: {
    qrcodeType: {
      type: String,
      default: 'login'
    },
    registration: {
      type: Object,
      required: false,
      default: () => ({
        terms: true,
        email: '',
        userType: 'consultant',
        nationalIdentityNumber: '',
        phone: ''
      })
    }
  },
  computed: {
    ...mapState(['qrcodeUrl']),
    isSafariOniOS () {
      return (
        /iP(ad|od|hone).*Safari/i.test(navigator.userAgent) &&
        !(
          /Chrome/i.test(navigator.userAgent) ||
          /CriOS/i.test(navigator.userAgent)
        )
      )
    },
    isChromeOnAndroid () {
      return (
        /Android/i.test(navigator.userAgent) &&
        /Chrome/i.test(navigator.userAgent)
      )
    }
  },
  beforeMount () {
    this.initializeQRCode()
  },
  methods: {
    async initializeQRCode () {
      try {
        let res
        if (this.qrcodeType === 'login') {
          res = await this.$http.get('login/qr/start')
        }
        if (this.qrcodeType === 'register') {
          res = await this.$http.get('register/qr/start', this.registration)
        }
        this.$store.commit(SET_SHOULD_CONTINUE_REFRESH, true)
        console.log('this.qrcodeType', this.qrcodeType)
        console.log('res.data', res.data)
        this.refreshQRCodeEverySecond(res.data)
      } catch (err) {
        this.$root.$emit('errorMessage', err.response)
      }
    },
    async refreshQRCodeEverySecond (payload) {
      try {
        const res = await this.$store.dispatch(this.qrcodeType === 'login' ? AUTH_REFRESH_QRCODE : AUTH_REFRESH_REGISTER_QRCODE, payload)
        console.log('res', res)
        if (res.token && res.status === 'complete') {
          this.$store.dispatch(GET_USER)
          this.$router.push({ name: 'dashboard' })
        }
      } catch (err) {
        this.$root.$emit('errorMessage', err.response)
      }
    },
    isMobileDevice () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      return /android|ipad|iphone|ipod/i.test(userAgent)
    },
    getLaunchUrlByDevice (autoStartToken, redirectUrl) {
      if (
        this.isSafariOniOS ||
        this.isChromeOnAndroid ||
        this.isMobileDevice()
      ) {
        return (
          'bankid:///?autostarttoken=' +
          autoStartToken +
          '&redirect=' +
          encodeURIComponent(redirectUrl)
        )
      } else {
        return (
          'https://app.bankid.com/?autostarttoken=' +
          autoStartToken +
          '&redirect=' +
          encodeURIComponent(redirectUrl)
        )
      }
    },
    onCancelLogin () {
      this.$store.commit(SET_SHOULD_CONTINUE_REFRESH, false)
      this.$emit('cancel-login')
    }
  }
}
</script>

<style scoped>
/* Add any custom styles here */
.theme--dark.v-tabs-items,
.theme--light.v-tabs-items {
  background-color: transparent !important;
}

.box {
  --b: 5px; /* thickness of the border */
  --c: white; /* color of the border */
  --w: 20px; /* width of border */
  --r: 5px; /* radius */

  padding: var(--b); /* space for the border */

  width: 240px;
  height: 240px;
  position: relative;
  /*Irrelevant code*/
  box-sizing: border-box;
  margin: 5px;
  display: inline-flex;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.box::before {
  content: "";
  position: absolute;
  inset: 0;
  background: var(--c, red);
  padding: var(--b);
  border-radius: var(--r);
  -webkit-mask: linear-gradient(0deg, #000 calc(2 * var(--b)), #0000 0) 50%
      var(--b) / calc(100% - 2 * var(--w)) 100% repeat-y,
    linear-gradient(-90deg, #000 calc(2 * var(--b)), #0000 0) var(--b) 50%/100%
      calc(100% - 2 * var(--w)) repeat-x,
    linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}
</style>
