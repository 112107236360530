<template>
  <v-dialog
    :value="show"
    persistent
    content-class="rounded-xl"
  >
    <v-card
      class="d-flex justify-center px-6"
      color="#265973"
    >
      <BankidAuth
        :qrcode-type="qrcodeType"
        @cancel-login="onCancelLogin()"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import bankidBlack from '@/assets/img/bankid_black.svg'
import BankidAuth from './BankidAuth.vue'
import { SET_SHOULD_CONTINUE_REFRESH } from '@/store/actions.type'

export default {
  name: 'QRCodeDialog',
  components: {
    BankidAuth
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    qrcodeType: {
      type: String,
      default: 'login'
    },
    registration: {
      type: Object,
      required: false,
      default: () => ({
        terms: true,
        email: '',
        userType: 'consultant',
        nationalIdentityNumber: '',
        phone: ''
      })
    }
  },
  data: () => ({
    bankidBlack
  }),
  computed: {},
  watch: {},
  mounted () {},
  beforeDestroy () {},
  methods: {
    isMobileDevice () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      return /android|ipad|iphone|ipod/i.test(userAgent)
    },
    isSafariOniOS () {
      return /iP(ad|od|hone).*Safari/i.test(navigator.userAgent) && !(/Chrome/i.test(navigator.userAgent) || /CriOS/i.test(navigator.userAgent))
    },
    isChromeOnAndroid () {
      return /Android/i.test(navigator.userAgent) && /Chrome/i.test(navigator.userAgent)
    },
    isAnyMobileDevice () {
      return this.isMobileDevice() || this.isSafariOniOS() || this.isChromeOnAndroid()
    },
    onCancelLogin () {
      this.$store.commit(SET_SHOULD_CONTINUE_REFRESH, false)
      this.$emit('cancel-login')
    }
  }
}
</script>
