export const PURGE_AUTH = 'logout'
export const RESET_STATE = 'resetState'
export const SET_AUTH = 'setAuth'
export const SET_ERROR = 'setError'
export const SET_PROFILE = 'setProfile'
export const SET_COMPANIES = 'setCompanies'
export const SET_CONTRACTS = 'setContracts'
export const SET_COMPANY_DETAILS = 'setCompanyDetails'
export const SET_COMPANY_MEMBERS = 'setCompanyMembers'
export const SET_COMPANY_INVITE_LINK = 'setCompanyInviteLink'
export const SET_PUBLIC_PROFILE = 'setPublicProfile'
export const SET_USER = 'setUser'
export const LOADING = 'loading'
export const SET_PICTURE = 'setPicture'
export const SET_COMPLETION = 'setCompletion'
export const SET_PRODUCTS = 'setProducts'
export const SET_EMAIL = 'setEmail'
export const SET_PHONE = 'setPhone'
export const SET_LOCATIONS = 'setLocations'
export const CLEAR_SEARCH_FILTERS = 'clearSearchFilters'
export const SET_SCROLL_POSITION = 'setScrollPosition'
export const SET_SEARCH_FILTERS = 'setSearchFilters'
export const SET_QR_DATA_URL = 'setqrcodeUrl'
export const SET_SHOULD_CONTINUE_REFRESH = 'setShouldContinueRefresh'
