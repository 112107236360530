const ID_TOKEN_KEY = 'id_token'

function isTokenValid (tokenEpoch) {
  const checkEpoch = new Date(0)
  checkEpoch.setUTCSeconds(tokenEpoch)
  return checkEpoch > new Date()
}

export const checkTokenExpired = (callback) => {
  const token = getToken()

  let decodedContent = ''
  try {
    decodedContent = atob(token.split('.')[1])
  } catch (error) {
    console.error('Failed to decode token', error)
    return callback(new Error('Failed to decode token'), false)
  }

  let expireObj = null
  try {
    expireObj = JSON.parse(decodedContent)
  } catch (error) {
    return callback(error, false)
  }

  const exp = expireObj.exp
  return callback(null, { tokenExpired: !isTokenValid(exp) })
}

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY)
}

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
}

export default { getToken, saveToken, destroyToken, checkTokenExpired }
