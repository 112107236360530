<template>
  <v-container
    class="ma-0 fill-height"
    fluid
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        sm="6"
        lg="4"
        xl="2"
      >
        <div class="top-card">
          <v-card
            color="neutral"
            class="rounded-xl"
          >
            <v-card-text
              v-if="!loading && ok"
              class="text-center"
            >
              <h2 class="mb-4">
                <v-icon x-large>
                  check_circle
                </v-icon>
              </h2>
              <h2
                class="mb-4"
                v-text="$t('Pages.Auth.congrats')"
              />
              <h4 v-text="$t('Pages.Auth.has_been_changed')" />
              <br>
              <v-divider />
              <br>
              <router-link
                v-slot="{ navigate }"
                :to="{ name: 'accounts-login' }"
                custom
              >
                <v-btn
                  id="continue"
                  rounded
                  depressed
                  class="cta-btn"
                  @click="navigate"
                >
                  {{ $t('Pages.Auth.next') }}
                </v-btn>
              </router-link>
            </v-card-text>
            <v-card-text
              v-if="loading"
              class="text-center"
            >
              <v-progress-circular
                :size="100"
                :width="2"
                color="accent"
                indeterminate
              />
            </v-card-text>
            <v-card-text
              v-if="!loading && !ok"
              class="text-center"
            >
              <h2 class="mb-4">
                <v-icon x-large>
                  do_not_disturb
                </v-icon>
              </h2>
              <h2 class="mb-4">
                Blasphemy!
              </h2>

              <h4 v-text="$t('Pages.Settings.unable_to')" />
              <br>
              <v-divider />
              <br>
              <router-link
                v-slot="{ navigate }"
                :to="{ name: 'accounts-login' }"
                custom
              >
                <v-btn
                  id="return"
                  rounded
                  depressed
                  class="cta-btn"
                  @click="navigate"
                >
                  {{ $t('Common.back') }}
                </v-btn>
              </router-link>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'OuterChangeEmail',
  props: {
    createAlert: {
      type: Function,
      default: function () { }
    }
  },
  data () {
    return {
      loading: true,
      ok: null
    }
  },
  beforeMount () {
    const hash = this.$route.query.hash // Fetch token from route query.
    // Send token for verification
    this.$http.get(`user/settings/email?hash=${hash}`).then(() => {
      this.createAlert('Your e-mail has been changed!', 'check_circle', 'success')
      this.loading = false
      this.ok = true
    }, err => {
      console.error(err)
      if (err.status === 404) {
        this.createAlert(this.$t('Validation.something_went_wrong'), 'warning', 'error')
      } else {
        this.$root.$emit('errorMessage', err.response)
      }
      this.loading = false
      this.ok = false
    })
  }
}
</script>
