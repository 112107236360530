<template>
  <PageLoader
    v-if="isPageLoading"
  />
  <v-container
    v-else
    fluid
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="4"
        xl="3"
        class="mt-4"
      >
        <div
          v-if="reference"
          class="top-card"
        >
          <v-card
            color="neutral"
            class="rounded-xl"
          >
            <v-card-text v-if="stepOne">
              <h2
                class="text-center"
                v-text="$t('Pages.Reference.approve_ref')"
              />
              <div
                class="text-center"
              >
                <strong>{{ reference.profile.firstName === '' ? '–' : reference.profile.firstName }} {{ reference.profile.lastName === '' ? '–' : reference.profile.lastName }}</strong> {{ $t('Pages.Reference.has_asked') }} {{ reference.company }}. {{ $t('Pages.Reference.do_you') }}
              </div>
              <br>
              <v-divider />
              <br>
              <div>
                <strong v-text="$t('Pages.Reference.company')" />
              </div>
              <div v-if="reference.company">
                {{ reference.company === '' ? '–' : reference.company }}
              </div>
              <br>
              <div>
                <strong v-text="$t('Pages.Reference.period')" />
              </div>
              <div>
                {{ reference.startDate === '' ? '–' : formatDate.yearOnly(reference.startDate) }}&nbsp;–&nbsp;{{ reference.endDate === null ? $t('Pages.Reference.ongoing') : formatDate.yearOnly(reference.endDate) }}
              </div>
              <br>
              <div v-if="reference.experience && reference.experience.jobTitles.length > 0">
                <strong>{{ reference.experience.jobTitles.length > 1 ? 'Positions' : 'Position' }}</strong>
              </div>
              <div v-if="reference.experience && reference.experience.jobTitles.length > 0">
                <v-chip
                  v-for="(exp, index) in reference.experience.jobTitles"
                  :key="index"
                  class="my-1 mr-2"
                  color="primary"
                  small
                >
                  {{ exp.jobTitle }}
                </v-chip>
              </div>
              <div v-if="reference.experience && reference.experience.skills && reference.experience.skills.length > 0">
                <strong>{{ reference.experience.skills.length > 1 ? $t('Pages.Reference.skills') : $t('Pages.Reference.skill') }}</strong>
              </div>
              <div v-if="reference.experience && reference.experience.skills && reference.experience.skills.length > 0">
                <v-chip
                  v-for="(skill, index) in reference.experience.skills"
                  :key="index"
                  class="my-1 mr-2 gradient-chip"
                  small
                >
                  {{ skill.skill }}
                </v-chip>
              </div>
              <div
                v-if="reference.profile"
                class="rating"
              >
                <strong>{{ $t('Pages.Reference.how_was') }} {{ reference.profile.firstName === '' ? '–' : userHandler.firstName(reference.profile) }}:</strong>
                <br>
                <p v-text="$t('Pages.Reference.tip')" />
                <div class="mt-1">
                  <v-tooltip
                    bottom
                    color="light-blue darken-4"
                  >
                    <template #activator="{ on }">
                      <v-icon
                        class="reference-icon-rating mr-1"
                        :color="feedback.rating === 1 ? 'accent' : '#FFF'"
                        v-on="on"
                        @click="feedback.rating = 1"
                      >
                        sentiment_very_dissatisfied
                      </v-icon>
                    </template>
                    <span v-text="$t('Pages.Reference.not_very')" />
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    color="light-blue darken-4"
                  >
                    <template #activator="{ on }">
                      <v-icon
                        class="reference-icon-rating mr-1"
                        :color="feedback.rating === 2 ? 'accent' : '#FFF'"
                        v-on="on"
                        @click="feedback.rating = 2"
                      >
                        sentiment_satisfied
                      </v-icon>
                    </template>
                    <span v-text="$t('Pages.Reference.average')" />
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    color="light-blue darken-4"
                  >
                    <template #activator="{ on }">
                      <v-icon
                        class="reference-icon-rating mr-1"
                        :color="feedback.rating === 3 ? 'accent' : '#FFF'"
                        v-on="on"
                        @click="feedback.rating = 3"
                      >
                        sentiment_very_satisfied
                      </v-icon>
                    </template>
                    <span v-text="$t('Pages.Reference.very_good')" />
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    color="light-blue darken-4"
                  >
                    <template #activator="{ on }">
                      <v-icon
                        class="reference-icon-rating"
                        :color="feedback.rating === 4 ? 'accent' : '#FFF'"
                        v-on="on"
                        @click="feedback.rating = 4"
                      >
                        star
                      </v-icon>
                    </template>
                    <span v-text="$t('Pages.Reference.amazing')" />
                  </v-tooltip>
                </div>
              </div>
              <br>
              <div class="comment">
                <v-textarea
                  v-model="feedback.review"
                  color="accent"
                  class="mb-0"
                  hide-details
                  :label="$t('Pages.Reference.write_a')"
                  outlined
                />
                <small v-text="$t('Pages.Reference.all_ratings')" />
              </div>
              <br>
              <div class="text-center">
                <v-btn
                  text
                  rounded
                  @click="approveDeclineReference('decline')"
                >
                  <v-icon left>
                    clear
                  </v-icon>{{ $t('Pages.Reference.decline') }}
                </v-btn>
                <v-btn
                  light
                  rounded
                  @click="approveDeclineReference('approve')"
                >
                  <v-icon left>
                    check
                  </v-icon>{{ $t('Pages.Reference.approve') }}
                </v-btn>
              </div>
            </v-card-text>
            <v-card-text v-if="hasMadeChoice">
              <h2 class="text-center mb-5">
                {{ $t('Pages.Reference.you_have') }}{{ choice === 'approve' ? $t('Pages.Reference.approved') : $t('Pages.Reference.declined') }} {{ $t('Pages.Reference.this_ref') }}
              </h2>
              <v-divider />
              <br>
              <div>
                <strong v-text="$t('Pages.Reference.name')" />
              </div>
              <div>{{ reference.profile.firstName === '' ? '–' : reference.profile.firstName }} {{ reference.profile.lastName === '' ? '–' : reference.profile.lastName }}</div>
              <br>
              <div>
                <strong v-text="$t('Pages.Reference.company')" />
              </div>
              <div v-if="reference.company">
                {{ reference.company === '' ? '–' : reference.company }}
              </div>
              <br>
              <div>
                <strong v-text="$t('Pages.Reference.period')" />
              </div>
              <div v-if="reference.startDate">
                {{ reference.startDate === '' ? '–' : formatDate.yearOnly(reference.startDate) }}&nbsp;–&nbsp;{{ reference.endDate === null ? $t('Pages.Reference.ongoing') : formatDate.yearOnly(reference.endDate) }}
              </div>
              <div v-if="reference.experience && reference.experience.jobTitles.length > 0">
                <strong>{{ reference.experience.jobTitles.length > 1 ? $t('Pages.Reference.positions') : $t('Pages.Reference.position') }}</strong>
              </div>
              <div v-if="reference.experience && reference.experience.jobTitles.length > 0">
                <v-chip
                  v-for="(exp, index) in reference.experience.jobTitles"
                  :key="index"
                  class="my-1 mr-2"
                  color="primary"
                  small
                >
                  {{ exp.jobTitle }}
                </v-chip>
              </div>
              <div v-if="reference.experience && reference.experience.skills.length > 0">
                <strong>{{ reference.experience.skills.length > 1 ? $t('Pages.Reference.skills') : $t('Pages.Reference.skill') }}</strong>
              </div>
              <div v-if="reference.experience && reference.experience.skills.length > 0">
                <v-chip
                  v-for="(skill, index) in reference.experience.skills"
                  :key="index"
                  class="my-1 mr-2 gradient-chip"
                  small
                >
                  {{ skill.skill }}
                </v-chip>
              </div>
              <br>
              <div class="rating">
                <strong>{{ $t('Pages.Reference.how_was') }} {{ reference.profile.firstName === '' ? '–' : userHandler.firstName(reference.profile) }}:</strong>
                <br>
                <div class="mt-1">
                  <v-tooltip
                    bottom
                    color="light-blue darken-4"
                  >
                    <template #activator="{ on }">
                      <v-icon
                        class="reference-icon-rating mr-1"
                        :color="feedback.rating === 1 ? 'accent' : '#FFF'"
                        v-on="on"
                      >
                        sentiment_very_dissatisfied
                      </v-icon>
                    </template>
                    <span v-text="$t('Pages.Reference.not_very')" />
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    color="light-blue darken-4"
                  >
                    <template #activator="{ on }">
                      <v-icon
                        class="reference-icon-rating mr-1"
                        :color="feedback.rating === 2 ? 'accent' : '#FFF'"
                        v-on="on"
                      >
                        sentiment_satisfied
                      </v-icon>
                    </template>
                    <span v-text="$t('Pages.Reference.average')" />
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    color="light-blue darken-4"
                  >
                    <template #activator="{ on }">
                      <v-icon
                        class="reference-icon-rating mr-1"
                        :color="feedback.rating === 3 ? 'accent' : '#FFF'"
                        v-on="on"
                      >
                        sentiment_very_satisfied
                      </v-icon>
                    </template>
                    <span v-text="$t('Pages.Reference.very_good')" />
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    color="light-blue darken-4"
                  >
                    <template #activator="{ on }">
                      <v-icon
                        class="reference-icon-rating"
                        :color="feedback.rating === 4 ? 'accent' : '#FFF'"
                        v-on="on"
                      >
                        star
                      </v-icon>
                    </template>
                    <span v-text="$t('Pages.Reference.amazing')" />
                  </v-tooltip>
                </div>
              </div>
              <br>
              <div class="comment">
                <p>
                  <strong v-text="$t('Pages.Reference.comment')" />
                  <br>
                  {{ feedback.review }}
                </p>
              </div>
            </v-card-text>
            <v-card-text
              v-if="loading"
              class="text-center"
            >
              <v-progress-circular
                :size="100"
                :width="2"
                indeterminate
                color="accent"
              />
            </v-card-text>
            <v-card-text
              v-if="invalidToken"
              class="text-center"
            >
              <v-row>
                <v-col cols="12">
                  <v-icon large>
                    error
                  </v-icon>
                </v-col>
                <v-col
                  class="text-center"
                  cols="12"
                >
                  <h3
                    class="text-h4"
                    v-text="$t('Pages.Reference.access_restricted')"
                  />
                  <span v-text="$t('Pages.Reference.missing')" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { formatDate } from '@/config/formatDate.js'
import { userHandler } from '@/config/userHandler.js'
import PageLoader from '@/common/PageLoader.vue'

export default {
  name: 'OuterReference',
  components: {
    PageLoader
  },
  props: {
    createAlert: {
      type: Function,
      default: function () { }
    }
  },
  data () {
    return {
      formatDate: formatDate,
      userHandler: userHandler,
      loading: false,
      isPageLoading: false,
      stepOne: true,
      hasMadeChoice: false,
      invalidToken: false,
      token: null,
      feedback: {
        review: '',
        rating: 2
      },
      reference: {
        email: '',
        experience: {
          company: '',
          endDate: '',
          jobTitles: [],
          monthsWorked: '',
          ongoing: null,
          skills: [],
          startDate: ''
        },
        choice: ''
      }
    }
  },
  beforeMount () {
    this.token = this.$route.params.token // Get token from route param query
    // console.log('token', this.token)
    this.getReferenceData(this.token)
  },
  methods: {
    getReferenceData (token) {
      this.isPageLoading = true
      this.$http.get('references/' + token).then(res => {
        this.reference = res.data.reference
        this.isPageLoading = false
      }, err => {
        console.error(err)
        if (err.status === 404) {
          this.createAlert(this.$t('Validation.something_went_wrong'), 'warning', 'error')
        } else if (err.status === 400 && err.data.errorCode === '400E010') {
          this.stepOne = false
          this.invalidToken = true
        } else {
          this.$root.$emit('errorMessage', err.response)
        }
        this.isPageLoading = false
        this.ok = false
      })
    },
    approveDeclineReference (choice) {
      this.choice = choice
      this.stepOne = false
      this.loading = true

      this.$http.put('references/' + this.token + '/' + choice, this.feedback)
        .then(() => {
          this.loading = false
          this.hasMadeChoice = true
        })
        .catch(err => {
          console.error(err)
          this.$root.$emit('errorMessage', err.response)
          this.loading = false
        })
    }
  }
}
</script>
