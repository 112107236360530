export const ADD_PAYMENT_CARD = 'addPaymentCard'
export const AUTH_LOGIN_COLLECT = 'authLoginCollect'
export const AUTH_LOGIN_START = 'authLoginStart'
export const AUTH_REFRESH_QRCODE = 'authRefreshQRCode'
export const AUTH_REFRESH_REGISTER_QRCODE = 'authRefreshRegisterQRCode'
export const AUTH_REGISTER = 'authRegister'
export const AUTH_REGISTER_COLLECT = 'authRegisterCollect'
export const AUTH_REGISTER_START = 'authRegisterStart'
export const CHANGE_EMAIL = 'changeEmail'
export const CHANGE_PAYMENT_DETAILS = 'changePaymentDetails'
export const CHANGE_PICTURE = 'changeProfilePicture'
export const CHANGE_SEARCHABLE = 'changeSearchable'
export const CHECK_ACTIVE_SUBSCRIPTION = 'checkhasActiveSubscription'
export const CHECK_AUTH = 'checkAuth'
export const CLEAR_SEARCH_FILTERS = 'clearSearchFilters'
export const COMPANY_INIT = 'companyInit'
export const DELETE_ACCOUNT = 'removeAccount'
export const DELETE_COMPANY_INVITE_LINK = 'deleteCompanyInviteLink'
export const EDIT_USER = 'editUser'
export const GET_COMPANIES = 'getCompanies'
export const GET_COMPANY_BY_LINK = 'getCompanyByLink'
export const GET_COMPANY_DETAILS = 'getCompanyDetails'
export const GET_COMPANY_INVITE_LINK = 'getCompanyInviteLink'
export const GET_COMPANY_MEMBERS = 'getCompanyMembers'
export const GET_CONTRACTS = 'getContracts'
export const GET_PRODUCTS = 'getProducts'
export const GET_PROFILE = 'getProfile'
export const GET_PROFILE_BY_ID = 'getProfileById'
export const GET_USER = 'getUser'
export const LOGOUT = 'logout'
export const RESEND_EMAIL = 'resendEmail'
export const SAVE_COMPANY_DETAILS = 'saveCompanyDetails'
export const SAVE_LOCATIONS = 'saveLocations'
export const UPDATE_COMPLETION = 'updateCompletion'
export const UPDATE_SCROLL_POSITION = 'updateScrollPosition'
export const UPDATE_SEARCH_FILTERS = 'updateSearchFilters'
export const VERIFY_EMAIL = 'verifyEmailLogin'
export const SET_SHOULD_CONTINUE_REFRESH = 'setShouldContinueRefresh'
