import Vue from 'vue'
import Vuex from 'vuex'
import JwtService from '@/common/jwt.service'
import createPersistedState from 'vuex-persistedstate'
import { actions } from './actions.js'
import { mutations } from './mutations.js'

Vue.use(Vuex)

const settingsInitialState = {
  platform: {
    isSearchable: true
  }
}

const getUserInitialState = () => {
  return {
    firstName: '',
    email: '',
    id: '',
    lastName: '',
    phone: '',
    picture: {
      key: '',
      url: ''
    },
    nationalIdentityNumber: null,
    verified: false,
    isAdmin: false,
    isPremium: false,
    settings: settingsInitialState,
    terms: {
      accepted: false
    }
  }
}
export const getInitialSearchFilters = () => ({
  searchTerm: '',
  languages: [],
  personality: '',
  educationLevel: '',
  locations: []
})

export const getInitialState = () => ({
  isAuthenticated: !!JwtService.getToken(),
  isAdmin: false,
  isPremium: false,
  isLoading: false,
  paymentDetails: {},
  phone: null,
  profile: {},
  publicProfile: {},
  searchScrollPosition: 0,
  refCount: 0,
  searchFilters: getInitialSearchFilters(),
  subscriptionPurchase: null,
  user: getUserInitialState(),
  brandName: 'GigPort',
  adminForCompanies: [],
  companyMembers: [],
  companyInviteLink: '',
  contracts: [],
  products: [],
  qrcodeUrl: '',
  shouldContinueRefresh: true
})

export const state = { ...getInitialState() }

const getters = {}

export default new Vuex.Store({
  state,
  actions: actions,
  mutations: mutations,
  getters,
  plugins: [createPersistedState()]
})
