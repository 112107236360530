import Vue from 'vue'
import Router from 'vue-router'

import store from '../store/index'

import innerRoutes from '@/config/innerRoutes.js'

import { i18n } from '@/plugins/i18n'
import VueMeta from 'vue-meta'
import AccountsView from '@/components/accounts/AccountsView.vue'
import LoginView from '@/components/accounts/LoginView.vue'
import RegisterUser from '@/components/accounts/RegisterUser.vue'
import RegisterCompany from '@/components/accounts/RegisterCompany.vue'
import BankidAuth from '@/components/accounts/BankidAuth.vue'
import VerifyEmail from '@/pages/auth/VerifyEmail.vue'
import OuterReference from '@/pages/profile/edit/OuterReference.vue'
import OuterChangeEmail from '@/pages/auth/OuterChangeEmail.vue'

Vue.use(Router)
Vue.use(VueMeta)

const languageRegex = /^\/([a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3})(?:\/.*)?$/
const fallbackLocale = 'sv' // languages.find(lang => lang.fallback === true).locale ?? 'sv'

const routes = [
  {
    path: '',
    redirect: { name: 'accounts-login', params: { lang: i18n.locale } }
  },
  {
    name: 'tos-users',
    path: 'tos-users',
    component: () => import(
      '@/pages/legal/OuterTerms.vue'
    )
  },
  {
    name: 'privacy-policy',
    path: 'privacy-policy',
    component: () => import(
      '@/pages/legal/OuterPrivacyPolicy.vue'
    )
  },
  {
    name: 'cookies',
    path: 'cookies',
    component: () => import(
      '@/pages/legal/Cookies.vue'
    )
  },
  {
    path: 'verify/reference/:token',
    name: 'verify-reference',
    component: OuterReference
  },
  {
    path: 'account/email/change',
    name: 'account-email-change',
    component: OuterChangeEmail
  },
  {
    path: 'accounts',
    component: AccountsView,
    children: [
      {
        path: '',
        name: 'accounts-login',
        component: LoginView
      },
      {
        name: 'register-user',
        path: 'register/user',
        component: RegisterUser
      },
      {
        name: 'register-company',
        path: 'register/company',
        component: RegisterCompany
      },
      {
        name: 'bankid-auth',
        path: 'bankid-auth',
        component: BankidAuth
      },
      {
        name: 'verify-email',
        path: 'verify/email',
        component: VerifyEmail
      }
    ]
  },
  {
    path: 'app',
    component: () => import('@/views/InnerView.vue'),
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.isAuthenticated) {
        if (to.query.redirect) {
          if (to.query.tab) {
            next({ path: to.query.redirect, query: { tab: to.query.tab }, params: { lang: i18n.locale } })
          } else {
            next({ path: to.query.redirect, params: { lang: i18n.locale } })
          }
        } else {
          next()
        }
      } else {
        if (to.name === 'accounts-login') {
          // User is already on the login page, don't redirect again
          next()
        } else {
          // Redirect to the login page
          next({ name: 'accounts-login', params: { lang: i18n.locale } })
        }
      }
    },
    children: innerRoutes.pages
  },
  {
    path: '*',
    redirect: to => {
      let lang = `/${fallbackLocale}`
      if (!languageRegex.test(lang)) lang = `/${fallbackLocale}`

      return `${lang}`
    }
  }
]

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/:lang([a-z]{2,3}|[a-z]{2,3}-[a-zA-Z]{4}|[a-z]{2,3}-[A-Z]{2,3})',
      component: () => import('@/views/Root.vue'),
      children: routes
    },
    {
      path: '*',
      redirect: to => {
        let lang = `/${fallbackLocale}`
        if (!languageRegex.test(lang)) lang = `/${fallbackLocale}`
        return `${lang}/404`
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})
